<template>
  <div>
    <div id="page-content">
      <div class="dispflex">
        <h1 class="hdrtitle">School Hub</h1>
       
      </div>

      <div class="row mt-2">
        <div class="col-lg-12">
          <div class="widjet">
            <div class="widjethdr dispflex">
              <div style="width: auto">
                <span class="iconsect blubg" style="padding-left: 11px"
                  ><i class="fas fa-user-tie"></i
                ></span>
                <h1>Assign Teachers</h1>
              </div>
            </div>
            <div class="p-3 widjetcontent">
              <form
                action=""
                method="post"
                id="mngteacher"
                accept-charset="utf-8"
              >
                <div class="form-row">
                  <div class="form-group input-group col-md-6 mt-3 pr-md-3">
                    <span class="has-float-label">
                      <input
                        type="text"
                        name="clsname"
                        :value="classAndSectionDetails.className"
                        readonly
                        class="form-control form-input"
                        id="clsnameid"
                        placeholder="Class Name"
                        required="required"
                      />
                      <label for="clsnameid"
                        >Class Name <span class="required">*</span></label
                      >
                    </span>
                  </div>
                  <div class="form-group input-group col-md-6 mt-3">
                    <span class="has-float-label">
                      <input
                        type="text"
                        name="clssect"
                        :value="classAndSectionDetails.sectionName"
                        readonly
                        class="form-control form-input"
                        id="clssectid"
                        placeholder="Class Section"
                        required="required"
                      />
                      <label for="clssectid"
                        >Class Section <span class="required">*</span></label
                      >
                    </span>
                  </div>
                  <!-- <div class="form-group input-group col-md-6 mt-3 pr-md-3">
                    <span class="has-float-label">
                      <input
                        type="text"
                        name="crtdate"
                        readonly
                        value="24/03/2021"
                        class="form-control form-input"
                        id="crtdateid"
                        placeholder="Created Date"
                        required="required"
                      />
                      <label for="crtdateid"
                        >Created Date <span class="required">*</span></label
                      >
                    </span>
                  </div>
                  <div class="form-group input-group col-md-6 mt-3">
                    <span class="has-float-label">
                      <input
                        type="text"
                        name="crtby"
                        readonly
                        value="Admin"
                        class="form-control form-input"
                        id="crtbyid"
                        placeholder="Created By"
                        required="required"
                      />
                      <label for="crtbyid"
                        >Created By <span class="required">*</span></label
                      >
                    </span>
                  </div> -->
                 
                </div>
              </form>
              <div class="mx-auto mt-4 multiselect">
                <div class="dual-listbox">
               

                  <!-- <DualListBox
                    :source="source"
                    :destination="destination"
                    label="name"
                     @onChangeList="onChangeList"
                  /> -->

                  <div class="tbllist mt-4">
                    <div class="restable">
                      <div class="resrow resheader">
                        <div class="cell">Teacher</div>
                        <div class="cell" style="text-align: center;">Subject</div>
                        <!-- <div class="cell">Subject Type</div> -->
                        <div class="cell">Is Admin</div>
                        <!-- <div class="cell">Action</div> -->
                      </div>

                      <!-- <div v-if="teacherSubjectList.length == 0" style="text-align: center;">
                        No Teachers Assigned
                      </div> -->
                      <div
                        class="resrow"
                        v-for="(teacherSubject, index) in teacherSubjectList"
                        :key="index"
                      >
                        <div class="cell" data-title="Teacher">
                          <select class="form-control form-input" id="teacherId" v-model="teacherSubject.teacherId" @change="checkIsAdmin(index)" >
                          <option :value="null">--Select--</option>
                          <option
                            v-for="(item, tIndex) in teachersList"
                            :key="tIndex"
                            :value="item._id"
                          >
                            {{ item.firstName + " " + item.lastName }}
                          </option>
                           
                        </select>
                        </div>
                       
                        <div class="cell" data-title="Subject" style="text-align: center;">
                          {{ getSubjectName(teacherSubject.subjectId) }}
                        </div>
                         <div class="cell chkbox" data-title="Select">
                            <label class="custcheckbox">
                              <input type="checkbox" :disabled="!teacherSubject.teacherId" @change="setIsAdmin(teacherSubject.isAdmin,index)" v-model="teacherSubject.isAdmin"/>
                              <span class="checkmarkchk" :style="!teacherSubject.teacherId ? 'cursor: not-allowed;color: lightgreen;' : 'color: lightgreen;'"></span>
                            </label>
                        </div>

                        <!-- <div class="cell" data-title="Action">
                          <button class="btn round orange tooltipt" :disabled="teacherSubject.isAdmin" @click="removeAssignedObject(index)">
                            <i class="far fa-trash-alt"></i>
                            <span class="tooltiptext">Delete</span>
                          </button>
                        </div> -->
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="widjetfooter text-center dkicon">
              <button
                type="submit"
                class="btn btnsml"
                id="svbtn"
                @click.prevent="updateAssignedObject"
              >
                  <span>Save</span>
              </button>
              <button @click.prevent="closePoup" type="button" class="btn btncl clsmdl">
                   <span>Cancel</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    
    <!-- <footer>&copy; Vidhyaan.</footer> -->
  </div>
</template>

<script>

export default {
  name: "ViAdmissionAssignTeacher",

  props: {

    teachersList: {
      type: Array
    },
    subjectsList: {
      type: Array
    },
    classSubjects: {
      type: Array
    },
    assignedObjectList: {
      type: Array
    },
    classAndSectionDetails: {
      type: Object
    }
  },

 
  data() {
    return {

      assignedObject: {
        teacherId: null,
        subjectId: null,
        isAdmin: false
      },
      teacher : {
        name: '',
        departMent: [],
        emailId: '',
        mobile : null,  
        status: '',
        staffID : '',
        employmentType : '',
        dateOfJoining : null,
        dateOfLeaving : null  
       
      },

      teacherSubjectList: [],
     // teachersList: []
    };
  },

  created() {
    this.initComp()
   
  },

  methods: {
    initComp(){

      this.teacherSubjectList = []
    if(this.assignedObjectList.length > 0){


     for(let x of this.assignedObjectList){
      if(this.classSubjects.includes(x.subjectId)){
        this.teacherSubjectList.push({
        teacherId: x.teacherId,
        subjectId: x.subjectId,
        isAdmin: x.isAdmin
      })
      }
     }

     let getSubjectIds = this.teacherSubjectList.map( x => x.subjectId)
     let missingSubjects =  this.classSubjects.filter(value => !getSubjectIds.includes(value));
     
     if(missingSubjects.length > 0){
      for(let x of missingSubjects){
        this.teacherSubjectList.push({
        teacherId: null,
        subjectId: x,
        isAdmin: false
      })
      }
     
     }

    }else{
     for(let x of this.classSubjects){
      this.teacherSubjectList.push({
        teacherId: null,
        subjectId: x,
        isAdmin: false
      })
     }
     this.teacherSubjectList[0].isAdmin = true
    }
   // this.teacherSubjectList = this.assignedObjectList;
    },
    redirectRoute(vipath) {
      if (this.$route.path !== vipath) this.$router.push({ path: vipath });
    },
     createnewTeacher(value){
     if (value) {
        if (value == "create") {
          this.redirectRoute('/admission/teacher')
        }
      }
    },
    checkIsAdmin(index){
      this.teacherSubjectList[index].isAdmin = false
    },
     setIsAdmin(value, index){
      if (value) {
        this.teacherSubjectList.forEach((data, i) => {
          if (i != index) {
            data.isAdmin = false;
          } else {
            data.isAdmin = true;
          }
        });
      }
    },
    showdrop() {
      var showdp = document.getElementById("showdropmenu");
      if (showdp.style.display === "block") {
        showdp.style.display = "none";
      } else {
        showdp.style.display = "block";
      }
      setTimeout(function() {
        showdp.style.display = "none";
      }, 10000);
      return false;
    },
   
    createAssignedObject() {
     if(this.assignedObject.teacherId && this.assignedObject.subjectId){
     const isExist = this.teacherSubjectList.find((x) => (x.teacherId == this.assignedObject.teacherId) && (x.subjectId == this.assignedObject.subjectId)); // check the old record is found
      
     if(isExist){
      this.$toasted.error(`Already ${ this.getTeacherName(this.assignedObject.teacherId) } teacher assigned for ${ this.getSubjectName(this.assignedObject.subjectId) } subject`);
     }else{
      
      let assignedObj = {...this.assignedObject};
      if(this.teacherSubjectList.length == 0){
        assignedObj.isAdmin = true
      }
      this.teacherSubjectList.push(assignedObj);
      this.assignedObject.subjectId = null;
      this.assignedObject.teacherId = null;
     }
     }

    },

    getTeacherName(id) {
      return this.teachersList.filter(x => x._id == id)[0].firstName;
    },

    getSubjectName(id) {
      return this.subjectsList.filter(x => x._id == id)[0].subjectName;
    },
     getSubjectType(id) {
       let details = this.subjectsList.filter(x => x._id == id)[0].isMandtory;
       return  details ? 'Mandatory' : 'Optional'
    },
    

    removeAssignedObject(index) {
      let totalList = [...this.teacherSubjectList];
      totalList.splice(index,1);
      this.teacherSubjectList = totalList;
    },

    updateAssignedObject() {
    
     // if(!this.assignedObject.subjectId && !this.assignedObject.teacherId){
        
        let checkAdminUser = this.teacherSubjectList.filter( x => x.isAdmin)
        
        if(checkAdminUser.length > 0){
          this.$emit("updateClass", this.teacherSubjectList);

        }else{
          // if(this.teacherSubjectList.length == 1){
          // this.$toasted.error("Please add teacher and subject");
          // }else{
            this.$toasted.error("At least one Teacher must be class admin. Please select IsAdmin");

          //}
        }
      // }else{
      //   this.$toasted.error("Please add teacher and subject");
      // }
      
    },

    closePoup(){
      this.$emit("closePoup");
    }
  },
};
</script>
